
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import dayjs from "dayjs";
import { Pageniation as Table } from "@/utils/decorator";
import t from "@common/src/i18n/t";
const base = namespace("base");
const learning = namespace("learning");
const packageInfo = namespace("packageInfo");
import FileUpload from "@/components/scope/file-upload.vue";
const serviceItem = namespace("service-item");


import { emptyToLine, splitThousands } from "@/utils/common";

@Component({
  components: {FileUpload}
})
@Table("loadListData")
export default class PackageInfoView extends Vue {
  @packageInfo.Action packageInfoDetail;
  @base.Action getDictionaryList;

  emptyToLine = emptyToLine;
  splitThousands = splitThousands;

  get breadData() {
    return [
      { name: "组合套餐", path: "/package/list" },
      { name: "套餐详情" }
    ];
  }
  storeTypeList: any = [];
  packageInfo: any = {};
  get packageCode() {
    return this.$route.params.packageCode;
  };

  created() {
    this.getDictionaryList("store_type").then(data => {
      this.storeTypeList = data;
    });
    if (this.packageCode.length > 0) {
      this.packageInfoDetail({packageCode:this.packageCode}).then(data => {
        this.packageInfo = data.data;
      });
    }
  }


  getStoreName(type) {
    let storeType = this.storeTypeList.find(item => item.dataValue == type);
    if (typeof storeType == 'undefined') {
      return "";
    }
    return storeType.dataDesc;
  };


}
